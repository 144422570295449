import { useEffect, useState } from "react";

const Root = () => {
    const [user, setUser] = useState(undefined);
    const [authModule, setAuthModule] = useState<any>();

    useEffect(() => {
        let sub;
        const loadAuthModule = async () => {
            const auth = await System.import('@deloitte-dsoe/portal-auth');
            setAuthModule(auth.authModule);
            console.log(authModule)
            sub = auth.context.subscribe(({ user }) => {
                setUser(user);
            });
        }
        loadAuthModule();
        return () => {
            sub?.unsubscribe();
        };
    });

    return (
        <div className="container-fluid bg-dark">
            <header className="bg-dark shadow-sm">
                <nav className="navbar sticky-top navbar-expand-lg navbar-dark" id="mainNav">
                    <a className="navbar-brand" href="/">                    
                        <img src="/assets/images/logo@2x.svg" alt="Deloitte"/>                                      
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbars-top" aria-controls="navbars-top" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbars-top">
                        <ul className="navbar-nav me-auto">
                            <li className="nav-item text-white">
                                <a className="nav-link text-white" style={{"paddingLeft": "0px", "paddingRight":"10px"}}>|&nbsp;&nbsp;&nbsp;Application Lifecycle Management</a>
                            </li>
                        </ul>
                        <div className="header-actions">
                            <ul className="navbar-nav">
                                {/* <li className="nav-item dropdown">
                                    <a className="nav-link" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 368 368" className="svg-icon">
                                            <path d="M344,144h-29.952c-2.512-8.2-5.8-16.12-9.792-23.664l21.16-21.16c4.528-4.528,7.024-10.56,7.024-16.984
                                            c0-6.416-2.496-12.448-7.024-16.976l-22.64-22.64c-9.048-9.048-24.888-9.072-33.952,0l-21.16,21.16
                                            c-7.536-3.992-15.464-7.272-23.664-9.792V24c0-13.232-10.768-24-24-24h-32c-13.232,0-24,10.768-24,24v29.952
                                            c-8.2,2.52-16.12,5.8-23.664,9.792l-21.168-21.16c-9.36-9.36-24.592-9.36-33.952,0l-22.648,22.64
                                            c-9.352,9.36-9.352,24.592,0,33.952l21.16,21.168c-3.992,7.536-7.272,15.464-9.792,23.664H24c-13.232,0-24,10.768-24,24v32
                                            C0,213.232,10.768,224,24,224h29.952c2.52,8.2,5.8,16.12,9.792,23.664l-21.16,21.168c-9.36,9.36-9.36,24.592,0,33.952
                                            l22.64,22.648c9.36,9.352,24.592,9.352,33.952,0l21.168-21.16c7.536,3.992,15.464,7.272,23.664,9.792V344
                                            c0,13.232,10.768,24,24,24h32c13.232,0,24-10.768,24-24v-29.952c8.2-2.52,16.128-5.8,23.664-9.792l21.16,21.168
                                            c9.072,9.064,24.912,9.048,33.952,0l22.64-22.64c4.528-4.528,7.024-10.56,7.024-16.976c0-6.424-2.496-12.448-7.024-16.976
                                            l-21.16-21.168c3.992-7.536,7.272-15.464,9.792-23.664H344c13.232,0,24-10.768,24-24v-32C368,154.768,357.232,144,344,144z
                                             M352,200c0,4.408-3.584,8-8,8h-36c-3.648,0-6.832,2.472-7.744,6c-2.832,10.92-7.144,21.344-12.832,30.976
                                            c-1.848,3.144-1.344,7.144,1.232,9.72l25.44,25.448c1.504,1.504,2.336,3.512,2.336,5.664c0,2.152-0.832,4.16-2.336,5.664
                                            l-22.64,22.64c-3.008,3.008-8.312,3.008-11.328,0l-25.44-25.44c-2.576-2.584-6.576-3.08-9.728-1.232
                                            c-9.616,5.68-20.04,10-30.968,12.824c-3.52,0.904-5.992,4.088-5.992,7.736v36c0,4.408-3.584,8-8,8h-32c-4.408,0-8-3.592-8-8v-36
                                            c0-3.648-2.472-6.832-6-7.744c-10.92-2.824-21.344-7.136-30.976-12.824c-1.264-0.752-2.664-1.112-4.064-1.112
                                            c-2.072,0-4.12,0.8-5.664,2.344l-25.44,25.44c-3.128,3.12-8.2,3.12-11.328,0l-22.64-22.64c-3.128-3.128-3.128-8.208,0-11.328
                                            l25.44-25.44c2.584-2.584,3.088-6.584,1.232-9.72c-5.68-9.632-10-20.048-12.824-30.976c-0.904-3.528-4.088-6-7.736-6H24
                                            c-4.408,0-8-3.592-8-8v-32c0-4.408,3.592-8,8-8h36c3.648,0,6.832-2.472,7.744-6c2.824-10.92,7.136-21.344,12.824-30.976
                                            c1.856-3.144,1.352-7.144-1.232-9.72l-25.44-25.44c-3.12-3.12-3.12-8.2,0-11.328l22.64-22.64c3.128-3.128,8.2-3.12,11.328,0
                                            l25.44,25.44c2.584,2.584,6.576,3.096,9.72,1.232c9.632-5.68,20.048-10,30.976-12.824c3.528-0.912,6-4.096,6-7.744V24
                                            c0-4.408,3.592-8,8-8h32c4.416,0,8,3.592,8,8v36c0,3.648,2.472,6.832,6,7.744c10.928,2.824,21.352,7.144,30.968,12.824
                                            c3.152,1.856,7.152,1.36,9.728-1.232l25.44-25.44c3.016-3.024,8.32-3.016,11.328,0l22.64,22.64
                                            c1.504,1.504,2.336,3.52,2.336,5.664s-0.832,4.16-2.336,5.664l-25.44,25.44c-2.576,2.584-3.088,6.584-1.232,9.72
                                            c5.688,9.632,10,20.048,12.832,30.976c0.904,3.528,4.088,6,7.736,6h36c4.416,0,8,3.592,8,8V200z"></path>
                                            <path d="M184,112c-39.696,0-72,32.304-72,72s32.304,72,72,72c39.704,0,72-32.304,72-72S223.704,112,184,112z M184,240
                                            c-30.88,0-56-25.12-56-56s25.12-56,56-56c30.872,0,56,25.12,56,56S214.872,240,184,240z"></path>
                                        </svg>
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-end" style={{"width":"180px"}}>
                                        <div className="p-2">
                                            <div className="fw-bold mb-2">Themes</div>
                                            <div className="themes">
                                                <a href="#" className="d-inline-block p-3 bg-primary mb-2"></a>
                                                <a href="#" className="d-inline-block p-3 bg-secondary mb-2"></a>
                                                <a href="#" className="d-inline-block p-3 bg-success mb-2"></a>
                                                <a href="#" className="d-inline-block p-3 bg-danger mb-2"></a>
                                                <a href="#" className="d-inline-block p-3 bg-warning mb-2"></a>
                                                <a href="#" className="d-inline-block p-3 bg-info mb-2"></a>
                                                <a href="#" className="d-inline-block p-3 bg-light mb-2"></a>
                                                <a href="#" className="d-inline-block p-3 bg-dark mb-2"></a>
                                            </div>
                                        </div>  
                                    </ul>
                                </li>&nbsp; */}
                                <li className="nav-item dropdown">
                                    <a className="nav-link bg-light text-secondary text-center fw-bold" href="#" data-bs-toggle="dropdown" aria-expanded="false">{user?.shortName}</a>
                                    <ul className="dropdown-menu dropdown-menu-end">
                                        <li className="pt-2 pb-2">
                                            <a className="dropdown-item" href="#">
                                                <span className="p-1 bg-secondary text-white text-uppercase">{user?.shortName}</span>
                                                <span className="ms-2">{user?.name}</span>
                                            </a>
                                        </li> 
                                        <li><hr className="dropdown-divider"/></li>
                                        <li><a className="dropdown-item" href={undefined} onClick={() => authModule?.logout()}>Logout</a></li> 
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav> 
            </header>
        </div>
    );
}

export default Root;